<template>
  <header>
    <nav class="navbar navbar-expand navbar-dark">
      <div class="container-fluid align-items-center">
        <a class="navbar-brand" href="/">
          <img src="@/assets/images/neumann_2023_logo.png" alt="Neumann 2023" />
        </a>
        <div class="navbar-text">
          Selejtező
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Header',
  props: ['initData','contents'],
  data() {
    return {

    }
  },
  methods:{
    changeLanguage(language){
      this.initData.loader = true

      let formData = new FormData()
      formData.append('language', language)

      this.$axios.post('/api/v1/user/language',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.language = language
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>
<style scoped>
header{
  position: relative;
  width: 100%;
  background-color: var(--headerBg);
  background-image: url("@/assets/images/subtract.png"), url("@/assets/images/union.png");
  background-repeat: no-repeat,no-repeat;
  background-position: right center,right center;
}
.navbar{
  height: 5rem;
}
.container-fluid{
  max-width: 1280px;
}
.navbar-brand{
  width: 223px;
  height: 53px;
}
.navbar-text{
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-right: 5rem;
}
@media screen and (max-width: 1400px) {
  header{
    background-position: right center,420px center;
  }
}
@media screen and (max-width: 768px) {
  header{
    background-image: url("@/assets/images/subtract.png"), none;
  }
}
</style>