<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <template v-if="questionImage">
          <transition name="image-fade" mode="out-in">
            <img class="question-image" :src="questionImage" alt="" :key="questionImage"/>
          </transition>
        </template>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5 col-sm-12">
        <div class="card p-md-3 p-2">
          <div class="card-body">
            <div class="question mb-3" v-html="questionData.question">
            </div>
            <div class="answers">
              <template v-if="questionType===1 || questionType===6">
                <div class="d-flex justify-content-end mt-5">
                  <button class="btn btn-primary" @click="answer" id="answerBtn" :data-hash="questionData.answers[0].hash">{{questionData.answers[0].answer}}</button>
                </div>
              </template>
              <template v-if="questionType===2 || questionType===3">
                <div @click="selectAnswer" class="answer d-flex align-items-center" v-for="answer in questionData.answers" :data-hash="answer.hash" :key="answer.hash">
                  <div class="checkmark"></div>
                  <div class="text">{{answer.answer}}</div>
                </div>
              </template>
              <template v-if="questionType===4">
                <div class="answer" v-for="answer in questionData.answers" :data-hash="answer.hash" :key="answer.hash">
                  <div class="mb-3">{{answer.answer}}</div>
                  <select class="form-control">
                    <option value="0">Válassz a megoldások közül</option>
                    <option :value="select.id" v-for="select in questionData.selects">{{select.text}}</option>
                  </select>
                </div>
              </template>
              <template v-if="questionType===5">
                <template v-for="answer in questionData.answers">
                  <div class="mb-3">
                    <input class="form-control answer-input" type="text" :placeholder="answer.answer" :data-hash="answer.hash" :key="answer.hash" maxlength="20">
                  </div>
                </template>
              </template>
            </div>
            <template v-if="questionType!==1 && questionType!==6">
              <div class="d-flex justify-content-end mt-5">
                <button class="btn btn-primary" @click="answer" id="answerBtn">Tovább</button>
              </div>
            </template>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {Response} from "@/classes/response";

export default {
  name: 'Question',
  props: ['initData','contents'],
  data(){
    return {

    }
  },
  computed:{
    questionData(){
      return this.initData.user.questionData
    },
    status(){
      let status = this.initData.user.status
      if(status === 'question'){
        this.initData.bigImage = null
      }
      return this.initData.user.status
    },
    questionType(){
      return this.questionData.type
    },
    questionImage(){
      let image = this.initData.user.questionData.image
      if(image===null){
        return false
      }
      let imagePath = ''
      if(isLocalHost===true){
        imagePath = '/img/questions/'+image
      }else{
        imagePath = '/app/img/questions/'+image
      }
      return imagePath
    },
    roomId(){
      let roomId = this.initData.user.questionData.roomId
      if(roomId===null){
        return false
      }
      this.initData.bigImage = roomId
    },
    language(){
      return this.initData.language
    }
  },
  watch:{
    status(){

    },
    roomId(){

    }
  },
  methods:{
    selectAnswer(e){
      if(this.questionType===2) {
        //RESET
        let answers = document.querySelectorAll('.answer')
        for(let i = 0;i<answers.length;i++) {
          answers[i].classList.remove('selected')
        }
        //SELECT
        let answerElement = e.target

        while (!answerElement.classList.contains('answer')) {
          answerElement = answerElement.parentNode
        }

        answerElement.classList.add('selected')
      }else if(this.questionType===3) {
        //SELECT
        let answerElement = e.target

        while (!answerElement.classList.contains('answer')) {
          answerElement = answerElement.parentNode
        }

        if(answerElement.classList.contains('selected')===true){
          answerElement.classList.remove('selected')
        }else{
          answerElement.classList.add('selected')
        }
      }
    },
    answer(){
      let answersHashes = []
      let answersInputs = []
      if(this.questionType===1){
        let answers = document.querySelectorAll('#answerBtn')
        let answer = answers[0]
        let hash = answer.dataset.hash
        answersHashes.push(hash)
      }else if(this.questionType===2 || this.questionType===3){
        let answers = document.querySelectorAll('.answer.selected')
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let hash = answer.dataset.hash
          answersHashes.push(hash)
        }
        if(answersHashes.length===0){
          this.initData.alerts.push({
            msg: 'Nincs megadva válasz.',
            type: 'error'
          })
          return false
        }
      }else if(this.questionType===4){
        let answers = document.querySelectorAll('.answer')
        let answerSelects = document.querySelectorAll('.answer select')
        let allSelected = true
        for(let i=0;i<answers.length;i++){
          let answer = answers[i]
          let select = answerSelects[i]
          let hash = answer.dataset.hash
          let selectValue = select.value
          if(selectValue==0){
            allSelected = false
          }
          answersHashes.push(hash)
          answersInputs.push(selectValue)
        }
        if(allSelected===false){
          this.initData.alerts.push({
            msg: 'Nincs megjelölve mindenhol a megoldás',
            type: 'error'
          })
          return false
        }
      }else if(this.questionType===5){
        let answerInputs = document.querySelectorAll('.answer-input')
        for(let i=0;i<answerInputs.length;i++){
          let answerInput = answerInputs[i]
          let hash = answerInput.dataset.hash
          let answerInputValue = answerInput.value
          answersHashes.push(hash)
          answersInputs.push(answerInputValue)
        }
      }else if(this.questionType===6){
        let answers = document.querySelectorAll('#answerBtn')
        let answer = answers[0]
        let hash = answer.dataset.hash
        answersHashes.push(hash)
      }

      let postData = {
        'answersHashes' : answersHashes,
        'answersInputs' : answersInputs
      }

      this.initData.loader = true

      this.$axios.post('/api/v1/user/answer',postData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              window.scrollTo(0,0);

              this.initData.user.status = responseData.data.status
              this.initData.user.questionData = responseData.data.questionData
              if(this.initData.user.status==='story-end'){
                console.log('story end')
                this.initData.user.ranking = responseData.data.ranking
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1280px;
  padding-top: 2rem;
  padding-bottom: 6rem;
}
.card{
  background-color: rgba(255,255,255,1);
}
.question{

}
.question:deep(p:last-child){
  margin-bottom: 0;
}
.question-image{
  max-width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.image-fade-enter-active{
  transition: opacity 2s ease-in-out;
}

.image-fade-enter-from{
  opacity: 0;
}

img.image-fade-enter-to{
  opacity: 1;
}

.answer{
  width: 100%;
  background: none;
  cursor: pointer;
  border: 1px solid #6C828D;
  padding: 18px 24px 18px 24px;
  line-height: 1.375rem;
  margin-bottom: 1rem;
}
.answer:hover,.answer.selected{
  border: 1px solid #B40041;
}
.text{
  padding-left: 24px;
}
.checkmark{
  display: block;
  position: relative;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border: 1px solid #6C828D;
}
.checkmark:after {
  display: none;
  content: "";
  position: absolute;
  left: 9px;
  top: 3px;
  width: 5px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.answer:hover .checkmark:after,.answer.selected .checkmark:after{
  display: block;
}
.answer:hover .checkmark,.answer.selected .checkmark{
  border: 1px solid #F59B3F;
  background-color: #F59B3F;
}
@media (max-width: 576px) {
}
</style>
