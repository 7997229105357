<template>
  <Header v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"></Header>
  <Loader :initData="initData"/>
  <InitLoader :initData="initData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in initData.alerts"/>
  </div>
  <router-view v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"/>
  <Footer v-if="initData.initLoader===false" :initData = "initData" :contents = "activeContent"></Footer>
</template>

<script>
import {reactive} from 'vue'
import Loader from "./components/Loader"
import InitLoader from "./components/InitLoader"
import Alerts from "./components/Alerts"
import Header from "./components/Header"
import Footer from "./components/Footer"
import {Response} from '@/classes/response.js'

export default {
  name: 'App',
  components: {
    InitLoader, Loader, Alerts, Header, Footer
  },
  data(){
    // DEFAULT INIT DATA
    const initData = reactive({
      'user' : null,
      'initLoader' : true,
      'loader' : false,
      'alerts' : [],
      'language' : null,
      'contents' : null,
      'bigImage' : null
    })

    return {
      initData
    }
  },
  created(){
    this.userInit()
  },
  computed:{
    activeContent(){
      let userLanguage = this.initData.language
      return this.initData.contents[userLanguage]
    },
    bigImage(){
      let bigImage = this.initData.bigImage
      console.log(bigImage)
      let body = document.querySelector('body')
      if(bigImage!==null && !body.classList.contains('bg-'+bigImage)){
        body.classList.remove('bg-1','bg-2','bg-3','bg-4','bg-5','bg-6','bg-7','bg-8','bg-9','bg-10','bg-11')
        body.classList.add('bg-'+bigImage)
      }else{
        body.classList.remove('bg-1','bg-2','bg-3','bg-4','bg-5','bg-6','bg-7','bg-8','bg-9','bg-10','bg-11')
      }
      return bigImage
    }
  },
  watch:{
    bigImage(){
    }
  },
  methods:{
    userInit(){
      this.$axios.get('/api/v1/user/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              console.log(responseData)
              this.initData.language = responseData.data.language
              this.initData.contents = responseData.data.contents
              this.initData.user = responseData.data.user
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.initLoader = false
          });

    }
  }
}
</script>
<style>
/* purgecss start ignore */
.bg-10{
  background: url('assets/images/rooms/login.jpg') center 5rem no-repeat;
}
.bg-11{
  background: url('assets/images/rooms/room_8.jpg') center 5rem no-repeat;
}
.bg-1{
  background: url('assets/images/rooms/room_1.jpg') center 5rem no-repeat;
}
.bg-2{
  background: url('assets/images/rooms/room_2.jpg') center 5rem no-repeat;
}
.bg-3{
  background: url('assets/images/rooms/room_3.jpg') center 5rem no-repeat;
}
.bg-4{
  background: url('assets/images/rooms/room_4.jpg') center 5rem no-repeat;
}
.bg-5{
  background: url('assets/images/rooms/room_5.jpg') center 5rem no-repeat;
}
.bg-6{
  background: url('assets/images/rooms/room_6.jpg') center 5rem no-repeat;
}
.bg-7{
  background: url('assets/images/rooms/room_7.jpg') center 5rem no-repeat;
}
.bg-8{
  background: url('assets/images/rooms/room_8.jpg') center 5rem no-repeat;
}
@media (min-width: 1921px) {
  .bg-1,.bg-2,.bg-3,.bg-4,.bg-5,.bg-6,.bg-7,.bg-8,.bg-9,.bg-10,.bg-11{
    background-size: cover;
  }
}
/* purgecss end ignore */
</style>
